$(document).ready(function () {
  $('.slider-pane').slick({
    infinite: false,
    dots: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 0,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  var sliderHeight = $('.slider-pane .slick-track').height();
  $('.slider-pane .slick-slide').css('height', sliderHeight + 'px');
});
