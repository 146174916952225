import videojs from 'video.js';
import ru from 'video.js/dist/lang/ru.json';
import kk from './kk';

const Component = videojs.getComponent("Component");
class AudioTitle extends Component {
    constructor(player, options = {}) {
        super(player, options);
        if (options.title) {
            this.updateTitle(options.title);
        }
    }

    createEl() {
        return videojs.dom.createEl('div', {className: 'vjs-x-audio-title'});
    }

    updateTitle(title) {
        videojs.emptyEl(this.el());
        videojs.appendContent(this.el(), title);
    }
}

function initPlain(element) {
    const title = element.getAttribute("data-title");
    const video = videojs(element, {
        audioOnlyMode: true,
        controlBar: {
            volumePanel: false,
            audioTitle: {title},
        },
    });

    video.ready(() => {
        // Переставляем элементы, чтобы позволить нам сверстать плеер.
        const progressBarContainer = document.createElement("div");
        progressBarContainer.classList.add("vjs-x-progress-bar-container");

        const curTime = video.el().querySelector(".vjs-current-time");
        const progressBar = video.el().querySelector(".vjs-progress-control");
        const dur = video.el().querySelector(".vjs-duration");
        progressBarContainer.append(curTime, progressBar, dur);

        const controlsContainer = document.createElement("div");
        controlsContainer.classList.add("vjs-x-controls-container");

        const audioTitle = video.el().querySelector(".vjs-x-audio-title");
        controlsContainer.append(audioTitle, progressBarContainer);

        video.el().querySelector(".vjs-control-bar").append(controlsContainer);

        const errorMessage = document.createElement("div");
        errorMessage.classList.add("vjs-x-error-message");
        errorMessage.innerText = gettext("Аудиозапись недоступна");
        video.el().append(errorMessage);
    });

    video.on("play", () => {
        const players = videojs.getPlayers();
        for (const playerId in players) {
            if (!players.hasOwnProperty(playerId)) continue;
            if (playerId === video.id()) continue;

            const player = players[playerId];
            player.pause();
            player.currentTime(0);
            player.hasStarted(false);
        }
    });
}

function initBlocks(selector, init) {
    const elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        init(element);
    }
}

function initVideoJs() {
    videojs.addLanguage('ru', ru);
    videojs.addLanguage('kk', kk);
    videojs.registerComponent("AudioTitle", AudioTitle);
    initBlocks(".vjs-x-plain-player", initPlain);
}

initVideoJs();
