/**
 * "123.00" => "123"
 * "123.10" => "123.1"
 * "123.11" => "123.11"
 * "10000" => "10 000"
 * @param price
 * @returns {string}
 */
export function formatPrice(price) {
  price = price.toString();
  if (!price) {
    return price;
  }

  const parts = price.split(".")
  let int, frac;
  if (parts.length >= 2) {
    int = parts[0]
    frac = parts[1]
  } else {
    int = parts[0]
    frac = ""
  }

  // "12345" -> ["345", "12"]
  const intSegs = [];
  while (int.length > 0) {
    intSegs.push(int.slice(-3));
    int = int.slice(0, -3);
  }
  // ["345", "12"] -> "12 345"
  int = intSegs.reverse().join(" ");

  if (frac.length > 2) {
    frac = frac.slice(0, 2)
  }

  if (frac) {
    return `${int}.${frac}`
  } else {
    return int;
  }
}
