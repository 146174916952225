import BaseMapBlock from '../common/map';

class MapBlock extends BaseMapBlock {
  initMap() {
    let centerLat = Number(this.baseElement.getAttribute('data-center-lat'));
    let centerLong = Number(this.baseElement.getAttribute('data-center-long'));
    let zoomLevel = Number(this.baseElement.getAttribute('data-zoom-level'));
    let officeLat = Number(this.baseElement.getAttribute('data-office-lat'));
    let officeLong = Number(this.baseElement.getAttribute('data-office-long'));
    let footLinePoints = JSON.parse(this.baseElement.getAttribute('data-foot-line-points'));
    let busLinePoints = JSON.parse(this.baseElement.getAttribute('data-bus-line-points'));

    let map = new ymaps.Map(this.baseElement, {
      center: [centerLat, centerLong],
      controls: ['zoomControl'],
      zoom: zoomLevel,
    });
    map.behaviors.disable('scrollZoom');
    map.behaviors.disable('multiTouch');
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      map.behaviors.disable('drag');
    }
    let footLine = new ymaps.Polyline(
      footLinePoints,
      {},
      {
        strokeColor: '#cc3d00',
        strokeWidth: 4,
        strokeOpacity: 0.5,
      }
    );
    let busLine = new ymaps.Polyline(
      busLinePoints,
      {},
      {
        strokeColor: '#00ff00',
        strokeWidth: 4,
        strokeOpacity: 0.5,
      }
    );
    let adr = new ymaps.Placemark(
      [officeLat, officeLong],
      {
        iconCaption: 'Синтоник',
      },
      {
        preset: 'islands#yellowDotIconWithCaption',
      }
    );
    map.geoObjects.add(adr).add(footLine).add(busLine);
  }
}

(function () {
  let baseElement = document.querySelector('#map-contacts');
  if (baseElement) {
    new MapBlock(baseElement);
  }
})();
