import {getCurrentLanguage} from "../../utils/i18n";

function installYandexMapsApi() {
  return new Promise((resolve, reject) => {
    if ('ymaps' in window) {
      resolve();
    }

    const lang = getCurrentLanguage();
    let scriptElement = document.createElement('script');
    scriptElement.src = `https://api-maps.yandex.ru/2.1/?lang=${lang}`;
    scriptElement.onload = () => {
      resolve();
    };
    scriptElement.onerror = () => {
      reject(new Error('Could not load Yandex Maps API'));
    };
    document.body.append(scriptElement);
  });
}

export default class BaseMapBlock {
  constructor(baseElement) {
    this.baseElement = baseElement;
    installYandexMapsApi().then(() => {
      ymaps.ready(() => {
        this.initMap();
      });
    });
  }

  initMap() {}
}
