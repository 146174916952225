import {getPhoneInputFormat} from "../../utils/i18n";

$(document).ready(function () {
  const format = getPhoneInputFormat();
  if (format) {
    $('input[type="tel"]').mask(format.mask, {
      placeholder: format.placeholder,
    });
  }
});
