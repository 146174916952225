$(document).ready(function () {
  var popup = $('#change-popup');

  function getPopupTitle(popup) {
    return popup.find('[data-anchor="title"');
  }

  if (!popup) return;

  var titles = {
    service: gettext('Заказать услугу'),
    solution: gettext('Заказать готовое решение'),
  };

  function changeTitle(popupType) {
    var popupTitle = getPopupTitle(popup);
    if (!popupTitle) return;
    popupTitle.html(titles[popupType]);
  }

  $('.call-change-popup').on('click', function (e) {
    var popupType = $(e.target).attr('data-popup-type');
    changeTitle(popupType);
    $.fancybox.open(popup, {
      touch: false,
    });
  });
});
