import * as Sentry from '@sentry/browser';

$(document).ready(function () {
  if (window.sentry_dsn !== '') {
    Sentry.init({dsn: window.sentry_dsn});
  }
  var testJSError = document.getElementById('test-js-error');
  if (testJSError) {
    testJSError.addEventListener('click', function (ev) {
      ev.preventDefault();
      throw new Error('Исключение JS вызванное вручную!');
    });
  }
});
