$('.slider-gallery').slick({
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  customPaging: function (slider, i) {
    return $('.slider-gallery__nav li:nth-child(' + (i + 1) + ')').html();
  },
  responsive: [
    {
      breakpoint: 576,
      settings: {
        dots: false,
      },
    },
  ],
});
