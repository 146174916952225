import NiceSelect from "../../vendor/nice-select2/js/nice-select2";

const initElements = new WeakSet();

export function createNiceSelect(baseElement) {
  if (initElements.has(baseElement)) {
    return;
  }
  new NiceSelect(baseElement);
  initElements.add(baseElement);
  baseElement.classList.add("nice-select-hidden");
}

(function () {
  let elements = document.querySelectorAll(".nice-select-init");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    createNiceSelect(element);
  }
})();
