import BaseMapBlock from '../common/map';
import mapMarkerUrl from '../../images/map-marker.png';

class MapBlock extends BaseMapBlock {
  initMap() {
    let iconConfig = {
      iconLayout: 'default#image',
      iconImageHref: mapMarkerUrl,
      iconImageSize: [60, 87],
      iconImageOffset: [-30, -87],
    };

    let placemarkLat = Number(this.baseElement.getAttribute('data-lat'));
    let placemarkLong = Number(this.baseElement.getAttribute('data-long'));

    let map = new ymaps.Map(this.baseElement, {
      center: [placemarkLat + 0.000085, placemarkLong - 0.003388],
      controls: ['zoomControl'],
      zoom: 17,
    });
    map.behaviors.disable('scrollZoom');
    map.behaviors.disable('multiTouch');
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      map.behaviors.disable('drag');
    }
    let adr1 = new ymaps.Placemark([placemarkLat, placemarkLong], {}, iconConfig);
    map.geoObjects.add(adr1);
  }
}

(function () {
  let baseElement = document.querySelector('#map');
  if (baseElement) {
    new MapBlock(baseElement);
  }
})();
