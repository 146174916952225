$('.slider-news').slick({
  infinite: false,
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  rows: 0,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});
