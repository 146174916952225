function fancyboxClose() {
  $.fancybox.close(true);
}
window.fancyboxClose = fancyboxClose;

$(document).ready(function () {
  var header = $('.header--home,.header--white');
  var h = 1;
  if (header.length > 0) {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if ($(window).width() >= 768) {
        if (scroll >= h) {
          if (!header.hasClass('is-sticky')) {
            header.addClass('is-sticky');
          }
        } else {
          header.removeClass('is-sticky');
        }
      }
    });
  }
});

$('[data-src="#menu-mob"]').fancybox({
  afterLoad: function () {
    $('body').addClass('body--menu');
  },
  afterClose: function () {
    $('body').removeClass('body--menu');
  },
});
