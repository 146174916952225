$(document).ready(function () {
  $('.filter-component').each(function (index, htmlElement) {
    var baseElement = $(htmlElement);
    if (!baseElement) {
      return;
    }

    var submitButton = baseElement.find('.filter-submit-button');
    if (submitButton) {
      submitButton.on('click', function () {
        var formArray = baseElement.serializeArray();
        var normalizedInputData = {};
        for (var i = 0; i < formArray.length; i++) {
          var inputValue = formArray[i].name.split('-');
          if (inputValue[0] in normalizedInputData) {
            normalizedInputData[inputValue[0]] = normalizedInputData[inputValue[0]].concat([
              inputValue[inputValue.length - 1],
            ]);
          } else {
            normalizedInputData[inputValue[0]] = [inputValue[inputValue.length - 1]];
          }
        }
        var url = new URL(window.location);
        url.search = ''; // Стираем старые параметры URL
        for (var key in normalizedInputData) {
          url.searchParams.set(key, normalizedInputData[key].join('-'));
        }
        window.location.href = url;
      });
    }

    var clearButton = baseElement.find('.filter-clear-button');
    if (clearButton) {
      clearButton.on('click', function () {
        baseElement.find('input:checkbox').prop('checked', false);
      });
    }

    var url = new URL(window.location);
    url.searchParams.forEach(function (value, key) {
      value.split('-').forEach(function (value) {
        baseElement
          .find('[name="' + key + '-' + value + '"]')
          .parents('.collapse')
          .collapse('show');
      });
    });
  });

  function renderReview(data) {
    var header = '<div class="reviews mb-30 mb-md-50">' + '<div class="reviews__head">';
    if (data.icon) {
      header += '<div class="reviews__icon">' + '<img src="' + data.icon + '" alt="">' + '</div>';
    }
    return (
      header +
      '<div class="reviews__author">' +
      '<b>' +
      data.fio +
      '.</b> ' +
      data.office_position +
      '. ' +
      data.title +
      '</div>' +
      '</div>' +
      '<p>' +
      data.text +
      '</p>' +
      '<a class="btn btn-link reviews__btn" href="' +
      data.url +
      '">Читать весь отзыв </a>' +
      '</div>'
    );
  }

  var _isLoading = false;
  $('.load_more').on('click', function (e) {
    if (_isLoading) return;
    _isLoading = true;

    var dataURL = $(e.target).attr('data-url');
    if (!dataURL) {
      return;
    }

    var newURL = new URL(window.location.origin + dataURL);
    // Копируем параметры чтобы в пагинации учитывался фильтр
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('offset', $('.reviews').length);
    // TODO: Перейти на ES5
    $.ajax({
      url: newURL + '?' + searchParams.toString(),
      method: 'get',
      dataType: 'json',
    })
      .done(function (data) {
        var has_more = data.has_more;
        var reviews = data.reviews;
        if (reviews.length === 0) return;
        for (var i = 0; i < reviews.length; i++) {
          $('.reviews__container').append(renderReview(reviews[i]));
        }
        if (!has_more) {
          $(e.target).remove();
        }
      })
      .fail(function (data) {
        console.log(data);
      })
      .always(function () {
        _isLoading = false;
      });
  });
});
