export function getCurrentLanguage() {
    const html = document.querySelector("html");
    const lang = html.getAttribute("lang");
    return lang || "ru";
}

const phoneFormats = {
    ru: {mask: "+7 (000) 000-00-00", placeholder: "+7 (123) 456-78-90"},
    kk: {mask: "+7 (000) 000-00-00", placeholder: "+7 (123) 456-78-90"},
    uz: {mask: "+998 (00) 000-00-00", placeholder: "+998 (12) 345-67-89"},
};

export function getPhoneInputFormat() {
    const html = document.querySelector("html");
    const formatName = html.getAttribute("data-phone-input-format");
    return phoneFormats[formatName || "ru"];
}
