class Stepper {
  constructor(root) {
    this.root = root;
    this.input = this.root.querySelector(".stepper__input");
    this.minValue = cleanInt(this.input.getAttribute("min"));  // может быть undefined
    this.maxValue = cleanInt(this.input.getAttribute("max")); // может быть undefined
    this.minusButton = this.root.querySelector(".stepper__button--minus");
    this.plusButton = this.root.querySelector(".stepper__button--plus");

    this.input.addEventListener("blur", () => this.onBlur());
    this.input.addEventListener("keydown", e => this.onKeyDown(e));
    this.input.addEventListener("change", () => this.onChange());

    this.minusButton.addEventListener("click", () => this.decrement());
    this.plusButton.addEventListener("click", () => this.increment());

    this.updateButtons();

    this.root.addEventListener("x:attrchange", () => {
      this.reloadAttrs();
    });
  }

  reloadAttrs() {
    this.minValue = cleanInt(this.input.getAttribute("min"));  // может быть undefined
    this.maxValue = cleanInt(this.input.getAttribute("max")); // может быть undefined
    this.updateButtons();
    this.setValueAndDispatch(this.cleanValue(this.input.value));
  }

  cleanValue(val) {
    let int = cleanInt(val, 0);
    int = this.clampInt(int);
    return int;
  }

  clampInt(int) {
    if (this.minValue !== undefined && int < this.minValue) {
      int = this.minValue;
    }
    if (this.maxValue !== undefined && int > this.maxValue) {
      int = this.maxValue;
    }
    return int;
  }

  onBlur() {
    this.setValueAndDispatch(this.cleanValue(this.input.value));
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.input.blur();
    }
  }

  onChange() {
    this.updateButtons();
  }

  updateButtons() {
    const val = this.cleanValue(this.input.value);

    const isMin = (this.minValue !== undefined && val <= this.minValue);
    this.minusButton.disabled = isMin;

    const isMax = (this.maxValue !== undefined && val >= this.maxValue);
    this.plusButton.disabled = isMax;
  }

  increment() {
    const val = this.cleanValue(this.input.value);
    this.setValueAndDispatch(this.clampInt(val + 1));
  }

  decrement() {
    const val = this.cleanValue(this.input.value);
    this.setValueAndDispatch(this.clampInt(val - 1));
  }

  setValueAndDispatch(val) {
    const oldInputValue = this.input.value;
    this.input.value = this.cleanValue(val);

    if (this.input.value !== oldInputValue) {
      this.input.dispatchEvent(new Event("input", {bubbles: true}));
      this.input.dispatchEvent(new Event("change", {bubbles: true}));
    }
  }
}

function cleanInt(...vals) {
  for (const val of vals) {
    const int = parseInt(val);
    if (!Number.isNaN(int)) {
      return int;
    }
  }
  return undefined;
}

(function () {
  const elements = Array.prototype.slice.apply(document.querySelectorAll(".stepper"));
  elements.forEach(e => {
    new Stepper(e);
  })
})();
