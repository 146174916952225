(function ($) {
  $.fn.endlessPaginate = function (options) {
    var defaults = {
        // Twitter-style pagination container selector.
      containerSelector: '.endless_container',
        // Twitter-style pagination link selector.
      moreSelector: 'a.endless_more',
        // Twitter-style pagination content wrapper selector.
      contentSelector: null,
        // Digg-style pagination page template selector.
      pageSelector: '.endless_page_template',
        // Digg-style pagination link selector.
      pagesSelector: 'a.endless_page_link',
        // Callback called when the user clicks to get another page.
      onClick: function () {},
        // Callback called when the new page is correctly displayed.
      onCompleted: function () {},
        // Set this to true to use the paginate-on-scroll feature.
      paginateOnScroll: false,
        // If paginate-on-scroll is on, this margin will be used.
      paginateOnScrollMargin: 1,
        // If paginate-on-scroll is on, it is possible to define chunks.
      paginateOnScrollChunkSize: 0,
    },
        settings = $.extend(defaults, options);

    var getContext = function (link) {
      return {
        key: (link.data('el-querystring-key') || 'page').split(' ')[0],
        url: link.attr('href'),
      };
    };

    function startLoading(link) {
      if (!link.data('original-text')) {
        link.data('original-text', link.innerText);
      }

      let currentWidth = link[0].getBoundingClientRect().width;
      link.css('width', `${currentWidth}px`);
      link.text('Загрузка...');
      link.data('is-loading', true);
    }

    function stopLoading(link) {
      let originalText = link.data('original-text') || 'Загрузить ещё';
      link.css('width', '');
      link.text(originalText);
      link.data('is-loading', false);
    }

    function isLoading(link) {
      return link.data('is-loading');
    }

    return this.each(function () {
      var element = $(this),
          loadedPages = 1;

      // Twitter-style pagination.
      element.on('click', settings.moreSelector, function () {
        var link = $(this),
            html_link = link.get(0),
            content_wrapper = element.find(settings.contentSelector),
            container = link.closest(settings.containerSelector);
        // Avoid multiple Ajax calls.
        if (isLoading(link)) {
          return false;
        }
        startLoading(link);
        var context = getContext(link);
        // Fire onClick callback.
        if (settings.onClick.apply(html_link, [context]) !== false) {
          var data = 'querystring_key=' + context.key;
          // Send the Ajax request.
          $.get(context.url, data, function (fragment) {
            // Increase the number of loaded pages.
            loadedPages += 1;

            if (!content_wrapper.length) {
              // Replace pagination container (the default behavior)
              container.before(fragment);
              container.remove();
            } else {
              // Insert the content in the specified wrapper and increment link
              content_wrapper.append(fragment);
              var nextPage = 'page=' + (loadedPages + 1);
              link.attr('href', link.attr('href').replace(/page=\d+/, nextPage));
              stopLoading(link);
            }

            // Fire onCompleted callback.
            settings.onCompleted.apply(html_link, [context, $.trim(fragment)]);
          }).fail(function (_xhr, _textStatus, _error) {
            // Remove the container left if any
            container.remove();
          });
        }
        return false;
      });

      // On scroll pagination.
      if (settings.paginateOnScroll) {
        var win = $(window),
            doc = $(document);
        doc.on('scroll', function () {
          if (doc.height() - win.height() - win.scrollTop() <= settings.paginateOnScrollMargin) {
            // Do not paginate on scroll if chunks are used and
            // the current chunk is complete.
            var chunckSize = settings.paginateOnScrollChunkSize;
            if (!chunckSize || loadedPages % chunckSize) {
              element.find(settings.moreSelector).trigger('click');
            } else {
              element.find(settings.moreSelector).addClass('endless_chunk_complete');
            }
          }
        });
      }

      // Digg-style pagination.
      element.on('click', settings.pagesSelector, function () {
        var link = $(this),
            html_link = link.get(0),
            context = getContext(link);
        // Fire onClick callback.
        if (settings.onClick.apply(html_link, [context]) !== false) {
          var page_template = link.closest(settings.pageSelector),
              data = 'querystring_key=' + context.key;
          // Send the Ajax request.
          page_template.load(context.url, data, function (fragment) {
            // Fire onCompleted callback.
            settings.onCompleted.apply(html_link, [context, $.trim(fragment)]);
          });
        }
        return false;
      });
    });
  };

  $.endlessPaginate = function (options) {
    return $('body').endlessPaginate(options);
  };
})($);

$.endlessPaginate();
