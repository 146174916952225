import {formatPrice} from "../../utils/format";

class TariffCalcVats {
  constructor(root) {
    this.root = root;

    this.baseCost = Number(this.root.getAttribute("data-base-cost"));
    this.employeesMin = Number(this.root.getAttribute("data-employees-min"));
    this.employeeCost = Number(this.root.getAttribute("data-employee-cost"));
    this.fmcCost = Number(this.root.getAttribute("data-fmc-cost"));
    this.currencySymbol = this.root.getAttribute("data-currency-symbol");

    this.inputs = {
      employeeCount: this.root.querySelector("[name=employee_count]"),
      recordStoragePeriod: this.root.querySelector("[name=record_storage_period]"),
      fmtCount: this.root.querySelector("[name=fmc_count]"),
    }

    const storageOptions = Array.prototype.slice.apply(this.root.querySelectorAll("[name=record_storage_period] option"));
    this.storagePeriods = storageOptions.map(option => {
      const id = option.value;
      const name = option.innerText.trim();
      const cost = Number(option.getAttribute("data-cost"));
      return {id, name, cost};
    });

    this.storagePeriodDescriptions = Array.prototype.slice.apply(this.root.querySelectorAll(".tariff-calc__label-description[data-storage-period]"));

    const optInputs = Array.prototype.slice.apply(this.root.querySelectorAll("input[data-option-name]"));
    this.options = optInputs.map(input => {
      const name = input.getAttribute("data-option-name");
      const cost = Number(input.getAttribute("data-option-cost"));
      const isPerEmployee = input.getAttribute("data-option-per-employee") === "1";
      const getTotal = () => {
        if (isPerEmployee) {
          return this.employeeCount * cost;
        } else {
          return cost;
        }
      };
      return {name, cost, isPerEmployee, input, getTotal};
    });

    this.invoiceRoot = this.root.querySelector(".tariff-calc__invoice");
    this.form = this.root.querySelector(".tariff-calc__submission-form");

    this.update();
    this.root.addEventListener("change", () => { this.update(); });
  }

  get employeeCount() {
    const cnt = Number(this.inputs.employeeCount.value);
    if (Number.isNaN(cnt)) {
      return this.employeesMin;
    } else {
      return cnt;
    }
  }

  get extraEmployeeCount() {
    const c = (this.employeeCount || 0) - (this.employeesMin || 0);
    return Math.max(c, 0);
  }

  get extraEmployeeTotalCost() {
    return this.extraEmployeeCount * this.employeeCost;
  }

  get fmcCount() {
    const cnt = Number(this.inputs.fmtCount.value);
    if (Number.isNaN(cnt)) {
      return 0;
    } else {
      return cnt;
    }
  }

  get fmcTotalCost() {
    const c = this.fmcCount * this.fmcCost;
    if (Number.isNaN(c)) {
      return 0;
    }
    return c;
  }

  get selectedStoragePeriod() {
    const id = this.inputs.recordStoragePeriod.value;
    for (const period of this.storagePeriods) {
      if (period.id === id) {
        return period;
      }
    }
    return undefined;
  }

  get selectedStoragePeriodTotalCost() {
    if (this.selectedStoragePeriod && this.selectedStoragePeriod.cost) {
      return this.selectedStoragePeriod.cost * this.employeeCount;
    } else {
      return 0;
    }
  }

  update() {
    this.updateInvoice();
    this.setFormValues();
    this.updateStoragePeriodDescription();
    this.updateFmcMax();
  }

  getTotal() {
    let total = unnan(this.baseCost);

    if (this.extraEmployeeCount > 0) {
      total += this.extraEmployeeTotalCost;
    }

    if (this.selectedStoragePeriod && this.selectedStoragePeriodTotalCost) {
      total += this.selectedStoragePeriodTotalCost;
    }

    if (this.fmcCount > 0) {
      total += this.fmcTotalCost;
    }

    this.options.forEach(option => {
      if (option.input.checked) {
        total += option.getTotal();
      }
    });

    return total;
  }

  updateInvoice() {
    const items = [
      {
        label: gettext("Базовый пакет услуг"),
        value: `${formatPrice(this.baseCost)}${this.currencySymbol}`,
      },
    ];

    if (this.extraEmployeeCount > 0) {
      items.push({
        label: `${gettext("Дополнительные сотрудники")} (${this.extraEmployeeCount})`,
        value: `${formatPrice(this.extraEmployeeTotalCost)}${this.currencySymbol}`,
      });
    }

    if (this.selectedStoragePeriod && this.selectedStoragePeriodTotalCost) {
      items.push({
        label: gettext("Хранение записей разговоров"),
        value: `${formatPrice(this.selectedStoragePeriodTotalCost)}${this.currencySymbol}`,
      });
    }

    if (this.fmcCount > 0) {
      items.push({
        label: gettext("Сотовые FMC"),
        value: `${formatPrice(this.fmcTotalCost)}${this.currencySymbol}`,
      });
    }

    this.options.forEach(option => {
      if (!option.input.checked) {
        return;
      }

      items.push({
        label: option.name,
        value: `${formatPrice(option.getTotal())}${this.currencySymbol}`,
      });
    });

    items.push({
      label: gettext("Итого"),
      value: `${formatPrice(this.getTotal())}${this.currencySymbol}/${gettext("мес.")}`,
      isTotal: true,
    });

    items.push({footnote: gettext("Все цены указаны без НДС")});

    this.renderInvoice(items);
  }

  setFormValues() {
    const set = (name, value) => {
      const input = this.form.querySelector(`[name=${name}]`);
      if (input) {
        input.value = value;
      }
    }

    set("base_cost", this.baseCost.toString());
    set("employee_count", this.employeeCount.toString());
    set("extra_employees_cost", this.extraEmployeeTotalCost.toString());

    if (this.selectedStoragePeriod && this.selectedStoragePeriodTotalCost) {
      set("record_storage_cost", this.selectedStoragePeriodTotalCost.toString());
      set("record_storage_period_text", this.selectedStoragePeriod.name);
    } else {
      set("record_storage_cost", "");
      set("record_storage_period_text", "");
    }

    set("fmc_count", this.fmcCount.toString());
    set("fmc_cost", this.fmcTotalCost.toString());

    const optionsJson = {options: []};
    this.options.forEach(option => {
      if (option.input.checked) {
        optionsJson.options.push({
          name: option.name,
          cost: option.getTotal().toString(),
        });
      }
    });
    set("options_json", JSON.stringify(optionsJson));

    set("total", this.getTotal().toString());
  }

  updateStoragePeriodDescription() {
    const id = this.selectedStoragePeriod ? this.selectedStoragePeriod.id : "0";
    this.storagePeriodDescriptions.forEach(desc => {
      if (desc.getAttribute("data-storage-period") === id) {
        desc.style.display = "";
      } else {
        desc.style.display = "none";
      }
    });
  }

  updateFmcMax() {
    this.inputs.fmtCount.setAttribute("max", this.employeeCount);
    this.inputs.fmtCount.dispatchEvent(new CustomEvent("x:attrchange", {bubbles: true}));
  }

  renderInvoice(items) {
    this.invoiceRoot.innerHTML = "";

    for (const item of items) {
      if (item.footnote) {
        this.invoiceRoot.appendChild(this.renderFootnote(item));
      } else {
        this.invoiceRoot.appendChild(this.renderInvoiceItem(item));
      }
    }
  }

  renderInvoiceItem(item) {
    const labelElem = document.createElement("div");
    labelElem.classList.add("invoice-list__item-label");
    labelElem.innerText = item.label;

    const valueElem = document.createElement("div");
    valueElem.classList.add("invoice-list__item-value");
    valueElem.innerText = item.value;

    const elem = document.createElement("li");
    elem.classList.add("invoice-list__item");
    if (item.isTotal) {
      elem.classList.add("invoice-list__item--total");
    }
    elem.appendChild(labelElem);
    elem.appendChild(valueElem);
    return elem;
  }

  renderFootnote(item) {
    const elem = document.createElement("li");
    elem.classList.add("invoice-list__footnote");
    elem.innerText = item.footnote;
    return elem;
  }
}

// Практично только для рендеринга, чтобы избежать случайных каскадных "NaN рублей"
function unnan(n) {
  if (Number.isNaN(n)) {
    return 0;
  } else {
    return n;
  }
}

(function () {
  let elements = document.querySelectorAll(".tariff-calc-vats");
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    new TariffCalcVats(element);
  }
})();
