$(document).ready(function () {
  $('.slider-team').slick({
    infinite: false,
    dots: false,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
});
