$(document).on('click', '.js-drop-mob', function () {
  var btn = $(this);
  if ($(window).width() >= 768) {
    btn.toggleClass('is-active').next().slideToggle('is-active');
  } else {
    btn.toggleClass('is-active').next().fadeToggle('is-active');
  }
});

$(document).on('click', '.js-drop-back', function () {
  $($(this).parents('.menu-mob__drop')[0]).hide(0).prev().removeClass('is-active');
});
