(function () {
  'use strict';
  window.addEventListener(
    'load',
    function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll('.form-block,.js-form-block');
      // Loop over them and prevent submission
      Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          'submit',
          function (event) {
            if (form.checkValidity() === false) {
              if (!$('.custom-file').hasClass('custom-file__has-file')) {
                $('#file-input .custom-file-label__file-name').css({
                  border: '1px solid #e15c39',
                  padding: '2px',
                  'border-radius': '2px',
                });
              }
              event.preventDefault();
              event.stopPropagation();
            } else {
              if ($(this)[0].vacancy) {
                $(this)[0].text.value =
                  'Вакансия: ' +
                $(this)[0].vacancy.value +
                '. Текст сообщения: ' +
                $(this)[0].text.value;
              }
              var formData = new FormData($(this)[0]);
              event.preventDefault();
              $.ajax({
                url: $(this).attr('action'),
                type: $(this).attr('method'),
                data: formData,
                contentType: false,
                processData: false,

                // handle a successful response
                success: function (data) {
                  if (data['status'] === 'success') {
                    var errorMsg = form.querySelector('.form-block__error-message');
                    if (errorMsg) {
                      errorMsg.classList.add('d-none');
                    }
                    // Получили ответ от сервера, что форма валидна и запрос прошел.
                    // удалили признаки валидации
                    form.classList.remove('was-validated');

                    // очищаем признаки валидации на полях ввода
                    var inputs = form.querySelectorAll('input');
                    for (let i = 0; i < inputs.length; i++) {
                      inputs[i].classList.remove('is-invalid');
                      inputs[i].classList.remove('is-valid');
                    }
                    // сбрасываем поля формы
                    form.reset();
                    // Закрываем активный fancybox, если он есть
                    $.fancybox.close();
                    // выводим сообщение пользователю
                    $.fancybox.open($('#success_send'), {
                      touch: false,
                    });
                    // после того как ошибки были скрыты, заменим их
                    var divs = form.querySelectorAll('.invalid-feedback');
                    for (let i = 0; i < divs.length; i++) {
                      var attr = divs[i].getAttribute('data-original-text');
                      if (attr) {
                        divs[i].innerHTML = attr;
                      }
                    }
                    return;
                  } else {
                    // в форме имеются ошибки
                    Object.keys(data['message']).forEach(function (key) {
                      // получаем поле ввода
                      var input = form.querySelector('[name=' + key + ']');
                      // если ошибок с полем нету, отмечаем его валидным
                      if (data['message'][key].errors.length === 0) {
                        input.classList.remove('is-invalid');
                        input.classList.add('is-valid');
                      }
                      // при наличии ошибок, выводим их в заготовленное поле
                      if (data['message'][key].errors.length !== 0) {
                        var div = form.querySelector('[data-id="' + input.id + '"]');
                        if (div) {
                          // добавляем ошибки с переносом строки
                          div.innerHTML = data['message'][key].errors.join('<br>');
                          input.classList.add('is-invalid');
                        }
                      }
                    });
                  }
                },
                // handle a non-successful response
                error: function (_xhr, _errmsg, _err) {
                  // выводим сообщение пользователю
                  var errorMsg = form.querySelector('.form-block__error-message');
                  if (errorMsg) {
                    errorMsg.innerHTML = gettext('Отправить запрос не удалось, попробуйте позже.');
                    errorMsg.classList.remove('d-none');
                  }
                },
              });
            }
            form.classList.add('was-validated');
          },
          false
        );
      });
    },
    false
  );
})();

$(document).ready(function () {
  $('.smooth').on('click', function (event) {
    event.preventDefault();
    var id = $(this).attr('href'),
        elem = $(id);
    if (!elem) return;
    $('body,html').animate({scrollTop: $(id).offset().top - 80}, 1000);
  });

  $('.js-select').select2({
    minimumResultsForSearch: Infinity,
    dropdownParent: '.select',
  });

  if ($('.custom-file-input').length) {
    $('.custom-file-input').on('change', function () {
      var fileName = $(this).val().split('\\').pop();
      $('.custom-file-label span.custom-file-label__file-name').text(fileName);
      $('.custom-file').addClass('custom-file__has-file');
      $('.custom-file-label span.custom-file-label__file-name').removeAttr('style');
    });
    $('.custom-file__delete').on('click', function () {
      $('.custom-file-label span.custom-file-label__file-name').text(gettext('Прикрепить резюме'));
      $('.custom-file').removeClass('custom-file__has-file');
      $('.custom-file-input').val('');
      $(this).hide();
    });
  }
});

$(window).on('load', function () {
  if ($('.custom-file-input').length) {
    var defaultText = $('.custom-file-input').val();
    if (defaultText !== '') {
      var fileName = $('.custom-file-input').val().split('\\').pop();
      $('.custom-file-label span.custom-file-label__file-name').text(fileName);
      $('.custom-file').addClass('custom-file__has-file');
    }
  }
});
