$(document).ready(function () {
  function renderSearchResult(data) {
    var start =
      '<tr>' +
    '         <td>' +
    '             <span class="search-result__num">' +
    data.number +
    '.</span>' +
    '         </td>' +
    '         <td>' +
    '             <a class="search-result__link" href="' +
    data.url +
    '">' +
    data.title +
    '</a>';
    if (data.parent_title && data.parent_url) {
      start +=
        '       <div class="text-muted">Раздел: ' +
      '                   <a href="' +
      data.parent_url +
      '" class="text-muted">' +
      data.parent_title +
      '</a>' +
      '                </div>';
    }
    return start + '              </td>' + '            </tr>';
  }

  var _isLoading = false;
  $('.load-more-search').on('click', function (e) {
    e.preventDefault();

    if (_isLoading) return;
    _isLoading = true;

    var dataURL = $(e.target).attr('data-url');
    if (!dataURL) {
      return;
    }

    var url = new URL(window.location.origin + dataURL);

    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set('offset', $('.search-result__num').length);

    $.ajax({
      url: url + '?' + searchParams.toString(),
      method: 'get',
      dataType: 'json',
    })
      .done(function (data) {
        var has_more = data.has_more;
        var results = data.results;
        if (results.length === 0) return;
        for (var i = 0; i < results.length; i++) {
          $('.search-result__table').append(renderSearchResult(results[i]));
        }
        if (!has_more) {
          $(e.target).remove();
        }
      })
      .fail(function (data) {
        console.log(data);
      })
      .always(function () {
        _isLoading = false;
      });
  });
});
