$(document).ajaxSuccess(function (event, request, settings) {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'ajaxSuccess',
    ajaxSuccessData: {
      url: settings.url || '',
      data: settings.data || '',
      responseText: request.responseText || '',
    },
  });
});
